import { LTFModel } from './../../models/ltf.model';
import { PatientModel } from './../../models/patient.model';
import { PdfMakeWrapper, Img } from 'pdfmake-wrapper';
import { TypeIDModel } from './../../models/type_id.model';
import { ApiResponseModel } from './../../models/api-response.model';
import { AccountModel } from './../../models/account.model';
import { ApiService } from './../../services/api.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from "@angular/router";
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { iif } from 'rxjs';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
    selector: 'app-patients',
    templateUrl: './patients.component.html',
    styleUrls: ['./patients.component.css'],
    host: {'class': 'component-wrapper'}
})
export class PatientsComponent implements  OnInit{
    @ViewChild('pdfContainer', {static: false}) pdfContainer: ElementRef = {} as any;
    isOnline = true;
    vaccination_status = false;
    studyType = "LAMP";
    dateOptions = {dateFormat: 'dd/mm/yyyy'};
    initialDate: IMyDateModel = {isRange: false};
    ltf: LTFModel = new LTFModel();
    model: IMyDateModel = {isRange: false};
    uploading = false;
    imageData = '';
    imageName = '';
    loginForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        firstname: ['', [Validators.required]],
        surname: ['', [Validators.required]],
        mobile_number: [''],
        parent_name: [''],
        parent_email: ['', [Validators.email]],
        parent_mobile_number: [''],
        laboratory: ['', [Validators.required]],
        document_number: ['', [Validators.required]],
        document_type: ['', [Validators.required]],
        country: [''],
        town: [''],
        postcode: [''],
        address1: [''],
        address2: [''],
        staff_id: [''],
        student_id: [''],
        birthdate: [this.model, [Validators.required]],
        sample_id: ['', [Validators.required]],

        result: ['', [Validators.required]],

        ethnicity: ['', [Validators.required]],
        gender: ['', [Validators.required]],

        country_from: ['', [Validators.required]],
        arrival_date: [this.model, []],
        transport_type: ['', [Validators.required]],
        transport_number: ['', [Validators.required]],
        vaccine_type: ['', [Validators.required]],
        passport_number: ['', [Validators.required]],
        booking: ['', [Validators.required]],
        activation_code: ['', [Validators.required]],
    });
    docDefinition: any = {};
    countries: string[] = [];
    captcha_response = '';
    canSubmit = false;
    account = '';
    shownAccount = '';
    accountInfo: AccountModel = {} as any;
    laboratories: LaboratoryModel[] = [];
    type_ids: TypeIDModel[] = [];
    selectedDocument = '';
    selectedLab = '';
    selectedCountry = '';
    older: number = 0;
    step = '';
    registering = false;
    submitted = false;
    logginIn = false;
    keyboardActive = false;
    document_type = '';
    isLoading = false;
    onlineStatusCheck: any = OnlineStatusType;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private online: OnlineStatusService,
        private ng2img: Ng2ImgMaxService
    ){
      this.online.status.subscribe((status: OnlineStatusType) => {
        this.isOnline = status == 1;
      });
    }

    ngOnInit() {

      this.route.params.subscribe((params) => {
          this.shownAccount = params.account;
          if(this.shownAccount == 'lateralgo'){
            this.api.lft = 'https://q8n9wdpwg7.execute-api.eu-west-2.amazonaws.com/test/';
            this.api.lftKey = 'WT2emmuFR45OVnkglKWv08pDEmCDl9hi8b9DujWM';
            this.api.lftAccount = '6022ebeb85bf4e2ebdbf26c87500f03d';
          }
          switch(this.shownAccount){
            case 'lme':
            case 'cov19care':
              this.account = 'lab-medical-expert';
              break;
            case 'hughes':
              this.account = 'vh-labs';
              break;
            default:
              this.account = this.shownAccount;
              break;
          }

          this.older = -1;
          /*let d = new Date();
          d.setFullYear(d.getFullYear() - 18);
          this.initialDate.singleDate = {
            jsDate: new Date(`${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`),
            date: {
              year: d.getFullYear(),
              month: d.getMonth() + 1,
              day: d.getDate()
            }
          };
          d = new Date();
          this.model.singleDate = {
            jsDate: new Date(`${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`),
            date: {
              year: d.getFullYear(),
              month: d.getMonth() + 1,
              day: d.getDate()
            }
          };*/
          this.f.birthdate.setValue(this.initialDate);
          this.f.arrival_date.setValue(this.model);
          this.api.getAccount(this.account).subscribe((info: any) => {
            if(typeof info['links'] === 'string') info['links'] = [info['links']];
            this.accountInfo = info as any;
            if(this.accountInfo.study === 'LFT'){
              this.studyType = 'LFT';
            }else{
              delete this.f.result;
              delete this.f.activation_code;
            }
            for(let f of this.accountInfo.disabled_fields)
              delete this.f[f];
            if(this.accountInfo.disabled_fields.includes('travel_information')){
              delete this.f['country_from'];
              delete this.f['arrival_date'];
              delete this.f['transport_type'];
              delete this.f['transport_number'];
            }else if(this.accountInfo.disabled_fields.includes('vaccination_status')){
              delete this.f['vaccine_type'];
              delete this.f['passport_number'];
              delete this.f['booking'];
            }

            this.api.getLaboratories(this.account).subscribe((labs) => {
              this.laboratories = labs as any;
              this.laboratories = this.laboratories.filter(x => x.status === 1);
              if(this.studyType == 'LFT') this.f.laboratory.setValue(this.laboratories[0].id);
              this.api.getTypeID(this.account).subscribe((arr) => {
                this.type_ids = arr as any;
                this.f.document_type.setValue(1);
                this.documentChanged({target: {value: 1}} as any);


              });
            });

          });

          this.countries = this.api.getCountries();
      });
    }

    get f() { return this.loginForm.controls; }

    forgot(){
        if(this.f.email.value === ''){
            alert('Introduce tu correo electrónico');
        }
    }
    canContinue(){

    }
    setRegistering(){
      this.step = 'registering';
    }
    setOlder(i: number){
      this.older = i;
      if(this.shownAccount == 'lateralgo') this.setRegistering();
    }
    async onSubmit(){
        this.submitted = true;
        if (this.loginForm.invalid) {
            /*for(let k of Object.keys(this.f))
              console.log(k, this.f[k].errors);*/
            return;
        }
        if(!this.isOnline){
          alert("You don't seem to have an active internet connection, please try again later");
          return;
        }
        this.submitted = true;
          if (this.loginForm.invalid) {
              /*for(let k of Object.keys(this.f))
                console.log(k, this.f[k].errors);*/
              return;
          }
          this.logginIn = true;
          let p = new PatientModel();
          let patient: any = {};

          for(let k of Object.keys(p))
            if(this.f[k]) patient[k] = this.f[k].value;
          const doc = {
            type_id: this.f.document_type.value,
            number: this.f.document_number.value
          };
          patient.vaccination_status = this.vaccination_status;

          if(this.f.birthdate){
            const idate = (this.f.birthdate.value as IMyDateModel).singleDate?.date;
            patient.birthdate = `${idate?.year}/${idate?.month}/${idate?.day}`;
          }
          if(this.f.arrival_date){
            const idate = (this.f.arrival_date.value as IMyDateModel).singleDate?.date;
            patient.arrival_date = `${idate?.year}/${idate?.month}/${idate?.day}`;
          }

          let sample_ids = this.f.sample_id.value.replace(/ /g,'').split(',');
          if(this.step != 'registering'){
            this.isLoading = true;
            if(this.studyType === 'LFT'){
              sample_ids = sample_ids[0];

              const result = this.f.result.value == "0" ? 'Positive' : this.f.result.value == "1" ? 'Negative' : 'Inconclusive';
              this.api.insertLFTPatient(this.captcha_response, this.account, patient, doc, this.f.laboratory.value, sample_ids,
                                        this.ltf, this.f.activation_code.value, result, this.imageData, this.shownAccount)
              .subscribe(resp => {
                this.step = 'finished';
                this.isLoading = false;
              }, error => {
                this.isLoading = false;
                console.log(error);
                if(error.error == "Verification code does not exist" || error.error == "Verification code does not match"){
                  alert(error.error);
                  this.step = "registering";
                }
              });
            }else{
              this.api.insertPatient(this.captcha_response, this.account, patient, doc, this.f.laboratory.value, sample_ids).subscribe(resp => {
                this.step = 'finished';
                this.isLoading = false;
              }, error => {
                this.isLoading = false;
                alert(error);
              });
            }
          }else {
            this.step = 'registered';
            this.scrollTo('logo');
          }


    }
  scrollTo(id: string){
    setTimeout(() => {
      let top = document.getElementById(id);
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    }, 500);
  }
  async resolved(response: string) {
      this.canSubmit = response !== null;
      if(this.canSubmit){
          this.captcha_response = response;
          //console.log(`Resolved captcha with response: ${response}`);
          if(!this.loginForm.invalid) this.continue();
      }else this.captcha_response = '';
  }
  documentChanged(ev: any){
    if(ev.target.value === '5'){
      let hash = this.hashFnv32a(ev.target.value+ (new Date().toString())) + this.hashFnv32a((new Date().toString()) + ev.target.value);
      hash = this.insertChar(hash, '-', 10);
      this.f.document_number.setValue(hash);
    }
    this.selectedDocument = (this.type_ids.find(x => x.id === ev.target.value * 1) as TypeIDModel).type_id;
  }

  labChanged(ev: any){
    this.selectedLab = (this.laboratories.find(x => x.id === ev.target.value * 1) as LaboratoryModel).name;
  }
  countryChanged(ev: any){
    this.selectedCountry = (this.countries.find(x => x === ev.target.value) as string);
  }
  continue(){
    if(this.step === 'registered' ){
      let error = false;
      for(let c of this.accountInfo.clauses) if(c.required && !c.checked) error = true;
      for(let c of this.accountInfo.futureClauses) if(c.required && !c.checked) error = true;
      console.log(error);
      if(error){
        alert('No all required checkboxes are marked');
      }else{
        this.onSubmit();
      }

    }else{
      this.step = 'registered';
    }
    return true;
  }
  edit(){
    this.step = 'registering';
  }

  private loadImage(file: string): Promise<Uint8Array>{
    return new Promise((resolve, reject) => {
        let req = new XMLHttpRequest();
        req.open('GET', file, true);
        req.responseType = 'arraybuffer';
        req.onload =  async(e) => {
            let data = new Uint8Array(req.response);
            resolve(data);
        };
        req.send();
    });
  }

  async pdf(){
    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
    /*const docDefinition =  {
      content: [

        'First paragraph',
        'Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines'
      ]
    };*/
    const logo = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/${this.shownAccount}.png`).build()).image;
    this.docDefinition = {
      content: [
        {image: logo, width: 200, style: 'centered'},
        {
          text: this.accountInfo.study != 'LFT' ? 'PARTICIPANT\nCONSENT FORM' : 'CONSENT FORM',
          style: ['header', 'centered']
        },
        {
          text: this.accountInfo.study != 'LFT' ? 'Participant information' : 'Information',
          style: ['subheader', 'blue']
        }
      ],
      defaultStyle: {
        margin: 24
      },
      styles: {
        header: {
          fontSize: 24,
          bold: true,
          characterSpacing: 6,
          margin: 8,
          width: 400
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 12]
        },
        blue:{
          color: '#1083ff;',
        },
        white: {
          color: '#ffffff'
        },
        strong: {
          bold: true
        },
        smallheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 12]
        },
        tabbed:{
          margin: [8, 12]
        },
        marginLeft:{
          margin: [8, 8, 0 ,0]
        },
        centered: {
          alignment: 'center'
        }
      }

    };
    this.addPdfLabeled('Name', this.f.firstname.value);
    this.addPdfLabeled('Surname', this.f.surname.value);
    this.addPdfLabeled('Email', this.f.email.value);
    if(this.f.birthdate) this.addPdfLabeled('Birth date', this.f.birthdate.value.singleDate.formatted);
    if(!this.older){
      if(this.f.parent_name) this.addPdfLabeled('Parent / Guardian\'s name', this.f.parent_name.value);
      if(this.f.parent_mobile_number) this.addPdfLabeled('Parent / Guardian\'s email', this.f.parent_email.value);
      if(this.f.parent_mobile_number) this.addPdfLabeled('Parent / Guardian\'s mobile phone', this.f.parent_mobile_number.value);
    }
    this.addPdfLabeled('Document number', this.f.document_number.value);
    this.addPdfLabeled('Type of document', this.selectedDocument);
    if(this.f.country) this.addPdfLabeled('Country / Region', this.selectedCountry);
    if(this.f.town) this.addPdfLabeled('Town / City', this.f.town.value);
    if(this.f.postcode) this.addPdfLabeled('Postcode', this.f.postcode.value);
    if(this.f.address1) this.addPdfLabeled('Address line 1 (or Company name)', this.f.address1.value);
    if(this.f.address2) this.addPdfLabeled('Address line 2', this.f.address2.value);
    if(this.f.staff_id) this.addPdfLabeled('Staff ID', this.f.staff_id.value);
    if(this.f.student_id) this.addPdfLabeled('Student ID', this.f.student_id.value);



    if(this.accountInfo.study != 'LFT'){
      this.docDefinition.content.push({text: 'Thank you for considering taking part in this research project. Please complete thisform after you have read the Information Sheet and/or listened to an explanation about the research project.', style: 'tabbed'});
      this.addPdfLabeled('Title of study', this.accountInfo.study);
      this.addPdfLabeled('Diagnostic Platform', this.accountInfo.platform);
      this.addPdfLabeled('Chief Investigator', `${this.accountInfo.representative.name} ${this.accountInfo.representative.surname}`);
      this.docDefinition.content.push({text: 'By checking each box, you are consenting to this element of the study. It will be assumed that un-checked boxes mean that you DO NOT consent to that part of the study and you may be deemed ineligible for the study.', style: 'tabbed'});
    }else{
      this.docDefinition.content.push({text: 'By checking each box, you are consenting to this element of the study. It will be assumed that un-checked boxes mean that you DO NOT consent and you may be deemed ineligible for the validation of your test.', style: 'tabbed'});
    }



    this.docDefinition.content.push({text: 'After signing this Consent Form, please keep a copy for your records. You may also keep the Information Sheet and refer to these at any time.', style: 'tabbed'});

    if(this.f.country_from){
      this.docDefinition.content.push({text: 'Travel information', style: ['subheader', 'blue']});
      this.addPdfLabeled('Country traveling back from', this.f.country_from.value);
      this.addPdfLabeled('Date of arrival', this.f.arrival_date.value.singleDate.formatted);
      this.addPdfLabeled('Transport type', this.f.transport_type.value);
      this.addPdfLabeled('Transport number', this.f.transport_number.value);
      this.addPdfLabeled('Vaccination status', this.vaccination_status ? 'Yes' : 'No');
      this.addPdfLabeled('Vaccination type', this.f.vaccine_type.value);
      this.addPdfLabeled('Passport number', this.f.passport_number.value);
      this.addPdfLabeled('Test Booking ref.', this.f.booking.value);
    }

    if(this.accountInfo.study != 'LFT') this.docDefinition.content.push({text: 'Taking part in a study', style: ['subheader', 'blue']});
    else this.docDefinition.content.push({text: 'Submitting my lateral flow test results', style: ['subheader', 'blue']});

    this.docDefinition.content.push({text: 'Statement', style: ['smallheader', 'tabbed']});
    for(let c of this.accountInfo.clauses)
      this.docDefinition.content.push({text: [
        {text: 'X\t', style: c.required ? 'blue': 'white'},
        c.text
      ], style: 'tabbed'});


    if(this.accountInfo.study != 'LFT') this.docDefinition.content.push({text: 'Future use of the information in the study', style: ['subheader', 'blue']});
    else this.docDefinition.content.push({text: 'Future use of the information', style: ['subheader', 'blue']});
    this.docDefinition.content.push({text: 'Statement', style: ['smallheader', 'tabbed']});
    for(let c of this.accountInfo.futureClauses)
        this.docDefinition.content.push({text: [
          {text: 'X\t', style: c.required ? 'blue': 'white'},
          c.text
        ], style: 'tabbed'});

    this.docDefinition.content.push({text: 'SIGNATURES', style: ['centered', 'subheader', 'blue']});
    const d = new Date();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    this.docDefinition.content.push({
			table: {
				headerRows: 1,
        widths: ['30%', '5%', '30%', '5%', '30%'],
				body: [
					[{text: `${this.f.firstname.value} ${this.f.surname.value}`, style: ['centered', 'strong'], border: [false, false, false, true]},
          {text: '', border: [false, false, false, false]},
          {text: `${months[d.getMonth()]} ${d.getDay()}, ${d.getFullYear()}`, style: ['centered', 'strong'], border: [false, false, false, true]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, true]}],
					[
            {text: 'Name of participant', style: 'centered', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: 'Date', style: 'centered', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: 'Signature', style: 'centered', border: [false, false, false, false]}]
				]
			}
    });
    pdfMake.createPdf(this.docDefinition).download();
    //pdfMake.createPdf(this.docDefinition).open({}, window);


  }
  addPdfLabeled(label: string, text: string){
    this.docDefinition.content.push({text:[
      {text: `${label}: ` , style: 'strong'},
      text
    ], style: 'tabbed'});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.isLoading = true;
      this.ng2img.compressImage(fileInput.target.files[0], 1).subscribe(
        result => {
          const resizedImage = new File([result], result.name);
          const reader = new FileReader();
          reader.onload = (e: any) => {
              const image = new Image();
              image.src = e.target.result;
              this.uploading = true;
              image.onload = rs => {
                //console.log(e.target.result);
                //data:image/png;base64,
                //data:application/octet-stream;base64
                //.replace(/^data:image\/\w+;base64,/, '');

                const imgBase64Path = (e.target.result as string).replace(/^data:\w+\/\w+(-\w+)*;base64,/, '');
                //console.log(imgBase64Path);
                /*this.api.readLFTTest(imgBase64Path, this.f.result.value, this.f.document_number.value).toPromise().then(resp => {
                  this.imageData = '';
                  this.uploading = false;
                  const output = resp as any;
                  if(output['utr']){
                    this.ltf = output;
                    this.f.sample_id.setValue(output['utr']);
                  }else{
                    this.f.sample_id.setValue('unavailable');
                    this.imageData = imgBase64Path;
                  }

                }).catch(_ => {
                  this.uploading = false;
                  this.f.sample_id.setValue('unavailable');
                  this.imageData = imgBase64Path;
                }).finally(() => {
                  this.imageName = fileInput.target.files[0].name;
                  this.isLoading = false;
                });*/
                this.f.sample_id.setValue('unavailable');
                  this.imageData = imgBase64Path;
                this.imageName = fileInput.target.files[0].name;
                  this.isLoading = false;
              };
            };
            reader.readAsDataURL(resizedImage);
        },
        err => {
          alert('An error happened while resizing the image.');
          this.isLoading = false;
        }
      );

    }
  }

  removeImage(){
    this.imageName = '';
    this.imageData = '';
    this.f.sample_id.reset();
    this.ltf = new LTFModel();
  }

  errored(ev: any) {
    console.warn(`reCAPTCHA error encountered`, ev);
  }

  private insertChar(str: string, c: string, position: number){
    return str.substring(0, position) + c + str.substring(position);
  }
  private hashFnv32a(str: string) {
    let i, l, hval = 0x811c9dc5;
    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
  }

}
