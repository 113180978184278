import { PatientModel } from '../../models/patient.model';
import { PdfMakeWrapper, Img } from 'pdfmake-wrapper';
import { TypeIDModel } from '../../models/type_id.model';
import { ApiResponseModel } from '../../models/api-response.model';
import { AccountModel } from '../../models/account.model';
import { ApiService } from '../../services/api.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from "@angular/router";
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import Crypto from 'crypto-js';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.css'],
    host: {'class': 'component-wrapper'}
})
export class ResultsComponent implements  OnInit{
    @ViewChild('pdfContainer', {static: false}) pdfContainer: ElementRef = {} as any;
    loginForm: FormGroup = this.formBuilder.group({
        patient_id: ['', [Validators.required]],
        sample_id: ['', [Validators.required]],
    });

    docDefinition: any = {};
    captcha_response = '';
    canSubmit = false;
    account = '';
    shownAccount = '';
    accountInfo: AccountModel = {} as any;
    step = 'login';
    patient_name = '';
    submitted = false;
    keyboardActive = false;
    sampleStatus = 0;
    result = 'inconclusive';
    hash = '';
    patientNumber = '';
    patient_birthdate = '';
    bundle = '';
    position = '';
    resultObject : any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService
    ){
      this.route.queryParams.subscribe(params => {
        this.hash = params.h;
      });

    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.shownAccount = params.account;
        switch(this.shownAccount){
          case 'lme':
          case 'cov19care':
            this.account = 'lab-medical-expert';
            break;
          case 'hughes':
            this.account = 'vh-labs';
            break;
          default:
            this.account = this.shownAccount;
            break;
        }
          this.api.getAccount(this.account).subscribe((info) => {
            this.accountInfo = info as any;
            this.hash = this.hash.split(' ').join('+');
            const reverted = Crypto.AES.decrypt(this.hash, this.accountInfo.name);
            const original = reverted.toString(Crypto.enc.Utf8);
            //console.log(original);
            let [number, bundle, position, tube] = original.split('|');
            this.bundle = bundle;
            this.position = position;
            this.patientNumber = number;
            this.f.patient_id.setValue(number);
            this.f.sample_id.setValue(tube);
          });
      });
    }

    get f() { return this.loginForm.controls; }

    forgot(){
        if(this.f.email.value === ''){
            alert('Introduce tu correo electrónico');
        }
    }
    canContinue(){

    }
    setRegistering(){
      this.step = 'registering';
    }
    async onSubmit(){

        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.api.getResults(this.captcha_response, this.f.patient_id.value, this.f.sample_id.value, this.bundle, this.position, this.account).subscribe((resp: any) => {
          if(resp[0]['user_result'] != null) resp[0]['result'] = resp[0]['user_result'];
          this.resultObject = resp[0];
          this.result = resp[0]['result'].toLowerCase();

          if(this.result != 'positive' && this.result != 'negative')
            this.result = 'inconclusive';
          this.patient_name = resp[0]['firstname'] + ' ' + resp[0]['surname'];
          if(resp[0]['birthdate']){
            const day = resp[0]['birthdate'].substr(8, 2);
            const month = resp[0]['birthdate'].substr(5, 2);
            const year = resp[0]['birthdate'].substr(0, 4);
            this.patient_birthdate = `${day}/${month}/${year}`;
          }

          this.step = 'finished';
        });

    }
  async resolved(response: string) {
      this.canSubmit = response !== null;
      if(this.canSubmit){
          this.captcha_response = response;
          console.log(`Resolved captcha with response: ${response}`);
          if(!this.loginForm.invalid) this.onSubmit();
      }else this.captcha_response = '';
  }
  async pdf(){
    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
    /*const docDefinition =  {
      content: [

        'First paragraph',
        'Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines'
      ]
    };*/
    if(this.accountInfo.study === 'LFT'){
      const logo = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/${this.account}.png`).build()).image;
      const certificate_title = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/certificate_title.png`).build()).image;

      const resultTableText = this.result == 'positive' ? 'Detected' : this.result == 'negative' ? 'Not Detected' : 'Indeterminate'

      this.docDefinition = {
        content: [
          {image: logo, width: 200, style: 'centered'},
          {
            text: 'DAY 2 TEST',
            style: ['header', 'centered']
          },
          {image: certificate_title, width: 150, style: 'centered'},
          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: [ '25%', '12%', '18%', '12%', '18%', '*' ],

              body: [
                [ 'Test', 'Result', 'Test ID', 'Date & Time Sample Taken', 'Date & Time Sample Processed', 'City & Country of Test' ],
                [ 'COVID-19 (SARS-CoV-2) Antigen Rapid Test', `SARS-CoV-2 ${resultTableText}`, this.f.sample_id.value, this.formatDBDate(this.resultObject.createdDate), this.formatDBDate(this.resultObject.updatedDate), 'United Kingdom' ]
              ]
            }, style: 'centered'
          },
          {
            text: 'PATIENT DETAILS',
            style: ['subheader']
          },
        ],
        defaultStyle: {
          margin: 8,
          fontSize: 8
        },
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            //characterSpacing: 6,
            margin: 2,
            width: 400
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 8]
          },
          blue:{
            color: '#1083ff;',
          },
          white: {
            color: '#ffffff'
          },
          strong: {
            bold: true
          },
          smallheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 12]
          },
          small: {
            fontSize: 6,
          },
          tabbed:{
            margin: [8, 12]
          },
          marginLeft:{
            margin: [8, 8, 0 ,0]
          },
          centered: {
            alignment: 'center'
          },
          blueBack: {
            background: '#0000ff',
            color: '#ffffff'
          }
        }

      };
      // this.addPdfLabeled('Document number', this.f.document_number.value);
      /*this.addPdfLabeled('Type of document', this.selectedDocument);
      if(this.f.country) this.addPdfLabeled('Country / Region', this.selectedCountry);*/

      //this.docDefinition.content.push({text: 'Thank you for considering taking part in this research project. Please complete thisform after you have read the Information Sheet and/or listened to an explanation about the research project.', style: 'tabbed'});
      console.log(this.resultObject);
      this.docDefinition.content.push();
      this.docDefinition.content.push({
        columns: [
          {
            width: '80%',
            layout: 'noBorders',
            table: {
              headerRows: 0,
              widths: ['30%', '*'],
              body: [
                ['First Name:', this.resultObject.firstname],
                ['Frist surame:', this.resultObject.surname],
                ['Gender:', this.resultObject.gender],
                ['Passport number:', this.resultObject.passport_number],
                ['Ethnicity:', this.resultObject.ethnicity],
                ['Date of birth:', this.formatDBDate(this.resultObject.birthdate)],
                ['Address:', this.resultObject.address1],

              ]
            }
          },
          [
            'QR Code',
            { qr: `https://forms.vidiia.com/${this.account}/results?h=${this.hash}`, fit: '120' }
          ]
        ]
      }
      );
      if(this.result == 'positive'){
        const positive_result = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/results/${this.account}_positive_result.png`).build()).image;
        this.docDefinition.content.push({image: positive_result, width: 520, style: 'centered'});
      }else if(this.result == 'negative'){
        const negative_result = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/results/${this.account}_negative_result.png`).build()).image;
        this.docDefinition.content.push({image: negative_result, width: 520, style: 'centered'});
      }else{
        const inconclusive_result = (await new Img(`https://d160cs2loxckik.cloudfront.net/assets/images/results/${this.account}_inconclusive_result.png`).build()).image;
        this.docDefinition.content.push({image: inconclusive_result, width: 520, style: 'centered'});
      }
      /*
      this.docDefinition.content.push({
        text: 'Test Details',
        style: ['subheader', 'blue']
      });
      this.docDefinition.content.push({
        text: 'COVID-19 (SARS-CoV-2) Test is an antigen test that detects SARS-CoV-2. The test is for detection of the SARS-CoV-2 virus. Please note the test was performed using the ROCHE SARS-CoV-2 Antigen Rapid Test Cassette. The "Patient Self Test" is a rapid chormatographic immunoassay for the qualitative detection of SARS-CoV-2 antigen.'
      });
      this.docDefinition.content.push({
        text: 'Clinical Assestment',
        style: ['subheader', 'blue']
      });


      let shortResultText;
      let longResultText;
      console.log(this.result);
      if(this.result == 'positive'){
        shortResultText = '';
        longResultText = '';
      }else if(this.result == 'negative'){
        shortResultText = 'Your coronavirus test result is NEGATIVE. You did not have the virus when the test was done. If you were self-isolating as a international arrival you may stop self-isolating.';
        longResultText = [
          'For advice on when you might need to self-isolate and what to do, go to:',
          {
            text: 'nhs.uk/conditions/coronavirus-covid-19',
            link: 'https://www.nhs.uk/conditions/coronavirus-covid-19/',
            style: ['blue']
          },
          {
            text: ' and read "Self-isolation and treating symptoms".\nIt´s a legal requirement to self-isolate when you arrive in the UK from a non-exempt country, territory or region. If you´re contacted by the enforcemente authorities or the police after you have received this negative result please show them this notification.'
          },
          {image: logos, width: 280, style: 'centered'},
        ];
      }else{ //Inconclusive
        shortResultText = 'Based solely on this swab result, I.';
        longResultText = [
          'Your coronavirus test result is unclear. It´s not possible to say if you had the virus when the test was done. You must, by law, continue self-isolating for the remainder of your self-isolation period as an international arrival travelling to the UK from a non-exempt country, territory or region. You may be contacted to check that you are self-isolating. If you want to shorten your self-isolation period you will need to take another test for international arrivals. For more information go to:',
          {
            text: 'gov.uk/guidance/coronavirus-covid-19-test-to-release-for-international-travel',
            link: 'https://www.gov.uk/guidance/coronavirus-covid-19-test-to-release-for-international-travel',
            style: ['blue']
          },
          {image: logos, width: 280, style: 'centered'},
        ];
      }

      this.docDefinition.content.push([
        shortResultText,
        {
          text: 'nhs.uk/conditions/coronavirus-covid-19',
          link: 'https://www.nhs.uk/conditions/coronavirus-covid-19/',
          style: ['blue']
        },
        {
          text: ' and read "Self-isolation and treating symptoms".'
        }
      ]);

      this.docDefinition.content.push({
        columns: [
          {
            width: '70%',
            text: ''
          },
          {
            width: '*',
            text: ' NOTE\t\t\t\t\t\t\t\t\t\t',
            style: 'blueBack'
          }
        ]
      });
      this.docDefinition.content.push({
        layout: 'noBorders',
        table: {
          headerRows: 0,
          widths: ['40%', '60%'],
          body: [
            [
              [
                'Kind regards,',
                {image: signature, width: 70, style: 'centered'},
                {text: 'Mr. Olukunle Akanbi', style: ['header', 'blue', 'centered']},
                {text: 'Ing, BASc, MRes, MIBMS', style: ['centered', 'small']},
                {text: 'HCPC No: BS68130', style: ['centered', 'small']},
                {text: 'Laboratory Manager', style: ['smallheader', 'blue', 'centered']},
                'PD 201 University of Wolverhampton science park, Glaisher Drive, WV10 9RU Wolverhampton',
                'Tel: 01902287065 | diagnostics@labmedexpert.co.uk',
              ],
              [
                longResultText
              ]
            ]
          ]
        }
      });
      this.docDefinition.content.push( {
            table : {
                headerRows : 1,
                widths: ['99%', '*'],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
      });
      this.docDefinition.content.push(
        ['The swab test was carried out by the patient. It is the patient´s responsibility to ensure they have followed the guidance provided, so as maximize the accureacy of the test. No Swab test is 100% reliable in detecting COVID-19. The clinician has not assested this person for COVID-19 SYMPTOMS.']
      );
      */

    }else{
      this.docDefinition = {
        content: [
          ' ',
          {
            text: 'Day 2 Test Result Certificate',
            style: ['header', 'centered']
          },
          ' ',
          ' ',
          {
            layout: {
              fillColor: (row: any, col: any, node: any) => { return row > 0 && row % 2 ? '#d9e2f3' : null;},
              hLineWidth: (i: number, node: any) => {
                return (i === 0 ) ? 0 : (i === 1) ? 2 : 1;
              },
              vLineWidth: (i: number, node: any) => {
                console.log(i, node);
                return (i === 0 || i === node.table.widths.length) ? 0 : 1;
              },
              hLineColor: (i: number, node: any) => {
                return '#8eaadb';
                //return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
              },
              vLineColor: (i: number, node: any) => {
                return '#8eaadb';
                //return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
              },
              /*hLineStyle: (i: number, node: any) => {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return {dash: {length: 10, space: 4}};
              },
              vLineStyle: (i: number, node: any) => {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return {dash: {length: 4}};
              },*/
            },
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 0,
              widths: [ '50%', '*' ],

              body: [
                [ 'Donor name', `${this.resultObject.firstname} ${this.resultObject.surname}`],
                [ 'Date of birth', {text: `${this.formatDBDate(this.resultObject.birthdate)}`, style: ['strong']}],
                [ 'Address', `${this.resultObject.address1}`],
                [ 'Sample type', {text: `Nasopharyngeal swab`, style: ['strong']}],
                [ 'Time when sample was taken', {text: `${this.formatDBDate(this.resultObject.createdDate)}`, style: ['strong']}],
                [ 'Testing type', {text: `For the presence of SARS-coV-2 Virus `, style: ['strong']}],
                [ 'Testing method', [`Reverse transcript rapid `,
                                      {text: 'PCR ', style: ['strong']},
                                      `molecular test with loop mediated amplification and AI `]],
                [ 'Passport number', {text: `${this.resultObject.passport_number}`, style: ['strong']}],
               /*
               ['First Name:', this.resultObject.firstname],
                ['Frist surame:', this.resultObject.surname],
                ['Gender:', this.resultObject.gender],
                ['Passport number:', this.resultObject.passport_number],
                ['Ethnicity:', this.resultObject.ethnicity],
                ['Date of birth:', this.formatDBDate(this.resultObject.birthdate)],
                ['Address:', this.resultObject.address1], */
              ]
            },
          },
          /*{
            text: 'Your coronavirus (COVID-19) test result is ',
            style: ['']
          },*/
        ],
        defaultStyle: {
          margin: 8,
          fontSize: 8
        },
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            //characterSpacing: 6,
            margin: 2,
            width: 400
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 8]
          },
          blue:{
            color: '#1083ff;',
          },
          white: {
            color: '#ffffff'
          },
          strong: {
            bold: true
          },
          smallheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 12]
          },
          small: {
            fontSize: 6,
          },
          tabbed:{
            margin: [8, 12]
          },
          marginLeft:{
            margin: [8, 8, 0 ,0]
          },
          centered: {
            alignment: 'center'
          },
          blueBack: {
            background: '#0000ff',
            color: '#ffffff'
          }
        }

      };
    }
    this.docDefinition.content.push(' ');
    if(this.result == 'positive'){
      this.docDefinition.content.push({text: [
        'Your N gene of SARS-COV-2 test result is ',
        {text: 'Positive', style: ['strong']},
        '. You were carrying the virus when the test was done.'
      ]});
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('If you have not had symptoms of coronavirus, you must self-isolate for 10 days from your test date. If you have symptoms of coronavirus, you must self-isolate for 10 days from the day your symptoms started, if earlier than when you took your test. (The day after symptom onset or test date is counted as the first full day of self-isolation).');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('People you live with should also self-isolate for 10 days from the day you took a test.');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('You may be contacted for contact tracing and to check that you, and those who you live with, are self-isolating.');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('You must not travel, including to leave the UK, during self-isolation.');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push({text: [
        'Contact ',
        {
          text: '111',
          link: 'tel:111',
          style: ['blue']
        },
        '. if you need medical help. In an emergency dial',
        {
          text: '999',
          link: 'tel:999',
          style: ['blue']
        },
      ]});
    }else if(this.result == 'negative'){
      this.docDefinition.content.push({text: [
        'Your N gene of SARS-COV-2 test result is ',
        {text: 'Negative', style: ['strong']},
        '. You did not have the virus when the test was done.'
      ]});
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('You should self-isolate if:');
      this.docDefinition.content.push(' - you get symptoms of coronavirus (you should get an NHS coronavirus test and self-isolate until you get the results)');
      this.docDefinition.content.push(' - you’re going into hospital (self-isolating until the date you go in)');
      this.docDefinition.content.push(' - someone you live with tests positive');
      this.docDefinition.content.push(' - you’ve been traced as a contact of someone who tested positive');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push({text: [
        'For advice on when you might need to self-isolate and what to do, go to ',
        {
          text: 'www.nhs.uk/conditions/coronavirus-covid-19',
          link: 'https://www.nhs.uk/conditions/coronavirus-covid-19',
          style: ['blue']
        },
        ' and read ‘Self-isolation and treating symptoms’. It’s a legal requirement to quarantine when you arrive in England. If you are contacted by the enforcement authorities or the police after you have received this positive result please show them this notification. '
      ]});
    }else {
      this.docDefinition.content.push({text: [
        'Your N gene of SARS-COV-2 test result is ',
        {text: 'Unclear', style: ['strong']},
        '. It’s not possible to say if you had the virus when the test was done.'
      ]});
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push('You must take another test immediately* to confirm either a positive or negative result.');
      this.docDefinition.content.push(' ');
      this.docDefinition.content.push(' If you have symptoms of coronavirus, you must self-isolate for 10 days from the day your symptoms started, if earlier than when you took your test. (The day after symptom onset or test date is counted as the first full day of self-isolation.) and repeat the test at the end of the isolation period.');

    }
    this.docDefinition.content.push(' ');
    this.docDefinition.content.push('Dr K. Laji ');
    this.docDefinition.content.push('Chief Medical Officer at VH-LABS and Consultant Physician and Endocrinologist, University Hospital Sussex ');
    this.docDefinition.content.push('St. Richards Hospital ');
    this.docDefinition.content.push({text: [
      'Tel: ',
      {
        text: '01483 688309',
        link: 'tel:01483688309',
        style: ['blue']
      }
    ]});
    this.docDefinition.content.push(' ');
    this.docDefinition.content.push('Testing Location');
    this.docDefinition.content.push('VH-LABS Bristol ');

    const d = new Date();
    pdfMake.createPdf(this.docDefinition).download(`certificate-${d.getDate()}_${d.getMonth()}_${d.getFullYear()}.pdf`);
    //pdfMake.createPdf(this.docDefinition).open();

  }

  formatDBDate(date: string){
    //1982-11-10T00:00:00.000Z
    if(!date || date == '') return '';
    const arr = date.split('T');
    const newDate = arr[0].split('-');
    arr[1] = arr[1].substr(0, 5) == '00:00' ? '' : arr[1].substr(0, 5);
    return `${newDate[2]}/${newDate[1]}/${newDate[0]} ${arr[1]}`;
  }

  addPdfLabeled(label: string, text: string){
    this.docDefinition.content.push({text:[
      {text: `${label}: ` , style: 'strong'},
      text
    ], style: 'tabbed'});
  }

}
