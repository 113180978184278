<div class="container {{shownAccount}}">
  <div class="logo-container">
    <a href="/{{shownAccount}}/patients">
    <img class="client-logo" src='/assets/images/{{shownAccount}}.png?1'/>
    </a>
  </div>

  <div class="" *ngIf="step === 'login'" >
    <h1>Welcome</h1>
    <p *ngIf="accountInfo.study != 'LFT'" >Please complete the form below to obtain your tests results. Ensure that you use both the <strong>Document ID</strong> and <strong>Sample ID</strong> sent to you on email.</p>
    <p *ngIf="accountInfo.study == 'LFT'" >Please complete the form below to obtain your tests results. Ensure that you use both the <strong>Passenger locator number</strong> and <strong>LFT ID</strong> sent to you on email.</p>

    <div >
      <form class="form-horizontal ion-margin-top" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

        <input class="form-input" placeholder="* Patient ID" formControlName="patient_id" name="patient_id"/>
        <input class="form-input" placeholder="* Sample ID" formControlName="sample_id" name="sample_id"/>
        <re-captcha class="margin-top" (resolved)="resolved($event)"></re-captcha>
        <input type="submit" [disabled]=[!canSubmit] value="Search my results" class="active button full-width margin-top"/>
      </form>
      <p class="disclaimer">* Required information</p>
    </div>
  </div>

  <div *ngIf="step === 'finished'" >
    <div>
      <h1>YOUR RESULTS</h1>
      <p class="text-center">The result of the test:<br/>

      <strong *ngIf="accountInfo.study != 'LFT'">PATIENT ID:</strong><br/>
      <strong *ngIf="accountInfo.study == 'LFT'">PASSENGER LOCATOR NUMBER:</strong><br/>
      {{f.patient_id.value}}<br/>
      <strong *ngIf="accountInfo.study != 'LFT'">SAMPLE ID:</strong><br/>
      <strong *ngIf="accountInfo.study == 'LFT'">TEST ID:</strong><br/>
      {{f.sample_id.value}}</p>
      <hr>
      <p *ngIf="accountInfo.study == 'LFT'" >
        <button class="button active" (click)="pdf()">Download</button>
      </p>
      <p *ngIf="accountInfo.study != 'LFT'" >
        <span class="capital">{{result}}</span> to N gene of SARS-COV-2
        <button class="button active" (click)="pdf()">Download</button>
      </p>
      <p *ngIf="accountInfo.study == 'LFT'" >
        COVID-19 (SARS-CoV-2) Antigen Rapid test
      </p>
      <div class="result-icon {{result}}"><span>{{result === 'inconclusive' ? 'u' : result === 'positive' ? '+' : '-' }}</span></div>
      <p class="uppercase">{{result == 'inconclusive' ? 'unclear' : result}}<br/></p>
      <p class="text-center" *ngIf="accountInfo.study != 'LFT'">
        <strong>Patient name: </strong> {{patient_name}}<br/>
        <strong>Patient ID: </strong> {{f.patient_id.value}}<br/>
        <strong>VH6 ID: </strong> {{f.sample_id.value}}<br/>
        <strong>Test type: </strong> {{accountInfo.study}}
      </p>
      <p class="text-center" *ngIf="accountInfo.study == 'LFT'">
        <strong>Patient name: </strong> {{patient_name}}<br/>
        <strong>Date of birth: </strong> {{patient_birthdate}}<br/>
        <strong>Test type: </strong> Antigen Rapid Test Day 2
      </p>


      <div *ngIf="result === 'positive'" >
        <p *ngIf="accountInfo.study != 'LFT'">Your N gene of SARS-COV-2 test result is <strong>POSITIVE</strong>.</p>

        <p>You were carrying the virus when the test was done.</p>
        <p>If you have not had symptoms of coronavirus, you must self-isolate for 10 days from your test date. If you have symptoms of coronavirus, you must self-isolate for 10 days from the day your symptoms started, if earlier than when you took your test. (The day after symptom onset or test date is counted as the first full day of self-isolation).
        <p>People you live with should also self-isolate for 10 days from the day you took a test.</p>
        <p>You may be contacted for contact tracing and to check that you, and those who you live with, are self-isolating.</p>
        <p>You must not travel, including to leave the UK, during self-isolation.</p>
        <p>Contact <a href="tel:111">111</a> if you need medical help. In an emergency dial <a href="tel:999">999</a>.</p>
    </div>
    <div *ngIf="result === 'negative'" >
        <p *ngIf="accountInfo.study != 'LFT'">Your N gene of SARS-COV-2 test result is <strong>NEGATIVE</strong>.</p>
        <p>You did not have the virus when the test was done.</p>
        <p>You should self-isolate if:</p>
        <p> - you get symptoms of coronavirus (you should get an NHS coronavirus test and self-isolate until you get the results)</p>
        <p> - you’re going into hospital (self-isolating until the date you go in)</p>
        <p> - someone you live with tests positive</p>
        <p> - you’ve been traced as a contact of someone who tested positive</p>
        <p>For advice on when you might need to self-isolate and what to do, go to <a href="https://www.nhs.uk/conditions/coronavirus-covid-19" target="_blank">www.nhs.uk/conditions/coronavirus-covid-19</a> and read ‘Self-isolation and treating symptoms’.</p>
    </div>
    <div *ngIf="result === 'inconclusive'" >
        <p *ngIf="accountInfo.study != 'LFT'">Your N gene of SARS-COV-2 test result is <strong>UNCLEAR</strong>.</p>
        <p>It’s not possible to say if you had the virus when the test was done.</p>
        <p>You must take another test immediately* to confirm either a positive or negative result.</p>
        <p>*If you have symptoms of coronavirus, you must self-isolate for 10 days from the day your symptoms started, if earlier than when you took your test. (The day after symptom onset or test date is counted as the first full day of self-isolation.) and repeat the test at the end of the isolation period.</p>
    </div>


    </div>
  </div>
