import { LTFModel } from './../models/ltf.model';
import { PatientModel } from './../models/patient.model';
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class ApiService {
    url = 'https://lvjcsv4c60.execute-api.eu-west-2.amazonaws.com/dev/';
    lft = 'https://414dibekma.execute-api.eu-west-2.amazonaws.com/prod/';
    lftKey = 'DBg7jx8Ibc3AhwbRXqfpl4WtFd9pYXzHahN44206';
    lftAccount = '4883213c56a34054a4baee38b0d4d1c1';
    headers: HttpHeaders = new HttpHeaders();
    headersJSON: HttpHeaders = new HttpHeaders();
    headersNC: HttpHeaders = new HttpHeaders();
    token = '';
    user: any;

    constructor(private http: HttpClient/*, private storage: Storage*/){
    }
    public setHeaders(token: string){
        this.token = token;
        this.headers = new HttpHeaders().append('Authorization', this.token).append('Content-Type' , 'application/x-www-form-urlencoded');
        this.headersNC = new HttpHeaders().append('Authorization', this.token);
        this.headersJSON = new HttpHeaders().append('Authorization', this.token).append('Content-Type' , 'application/json');
    }

    /*public getCultivos(params?: any): Promise<CultivoModel[]>{
        return new Promise((resolve, reject) => {
            if(this.crops.length === 0)
                this.get('Cultivos', params, 0).then((crops: CultivoModel[]) => {
                    for(let i = 0; i < crops.length; i++) crops[i].calculadora = crops[i]['calculadora'] as any === 1;
                    this.crops = crops;
                    resolve(this.crops);
                });
            else resolve(this.crops);
        });
    }*/

    public readLFTTest(image: string, result: string, reference: string){

      const headers: HttpHeaders = new HttpHeaders().append('x-api-key', this.lftKey);
      const params = {
        image: image,
        result: result,
        reference: reference,
        accountCode: this.lftAccount
      };
      return this.http.post(this.lft , params, {headers});
    }

    public insertUser(obj: any){
      const headers = this.headers;
      return this.http.post(this.url + 'users', obj, {headers});
    }

    public insertGroup(obj: any){
      const headers = this.headers;
      return this.http.post(this.url + 'groups', obj, {headers});
    }

    public getGroups(params?: any){
        return this.get('groups',params, 0);
    }

    public ping(){
      const headers = this.headers;
      return this.http.post(this.url + 'open/ping', {}, {headers});
    }

    public getAccount(account: string){
      const headers = this.headers;
      const obj = {account: account};
      return this.http.post(this.url + 'open/account', obj, {headers});
    }

    public getClauses(account: string){
      const headers = this.headers;
      const obj = {account: account};
      return this.http.post(this.url + 'open/clauses', obj, {headers});
    }

    public insertUserGroup(obj: any){
      const headers = this.headers;
      return this.http.post(this.url + 'users_groups', obj, {headers});
    }
    public deleteUserGroup(params?: any){
      return this.del('users_groups', params);
    }
    public deleteUser(params?: any){
      return this.del('users', params);
    }
    public deleteGroup(params?: any){
      return this.del('groups', params);
    }


    /** Devices  */
    public insertDevice(obj: any){
      const headers = this.headers;
      return this.http.post(this.url + 'devices', obj, {headers});
    }

    public getDevices(params?: any){
        return this.get('devices',params, 0);
    }

    public deleteDevice(params?: any){
      return this.del('devices', params);
    }
    public getResults(captcha: string, patient_id: string, sample_id: string, bundle: string, position: string, account: string){
      const headers = this.headers;
      const obj = {
        captcha: captcha,
        sample_id: sample_id,
        patient_id: patient_id,
        account: account,
        bundle: bundle,
        position: position
      };
      return this.http.post(this.url + 'open/results', obj, {headers});
    }
    public insertPatient(captcha: string, account: string, patient: PatientModel, doc: any, laboratory_id: number, sample_ids: string[]){
      const headers = this.headers;
      const obj = {
        captcha: captcha,
        account: account,
        patient: patient,
        document: doc,
        samples_id: sample_ids,
        laboratories_id: laboratory_id
      };
      return this.http.post(this.url + 'open/patient', obj, {headers});
    }
    public insertLFTPatient(captcha: string, account: string, patient: PatientModel, doc: any, laboratory_id: number, sample_ids: string[], lft: LTFModel,
        activation_code: string, result: string, imageData: string, shownAccount: string){
      const headers = this.headers;
      const obj = {
        captcha: captcha,
        account: account,
        patient: patient,
        document: doc,
        samples_id: sample_ids,
        laboratories_id: laboratory_id,
        lft: lft,
        image: imageData,
        result: result,
        activation_code: activation_code,
        shownAccount: shownAccount
      };
      return this.http.post(this.url + 'open/lft_patient', obj, {headers});
    }
    public getCountries(){
      return [
        "United Kingdom",
        "United States of America (the)",
        "France",
        "Germany",
        "Italy",
        "Spain",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas (the)",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory (the)",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands (the)",
        "Central African Republic (the)",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands (the)",
        "Colombia",
        "Comoros (the)",
        "Congo (the Democratic Republic of the)",
        "Congo (the)",
        "Cook Islands (the)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic (the)",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (the) [Malvinas]",
        "Faroe Islands (the)",
        "Fiji",
        "Finland",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories (the)",
        "Gabon",
        "Gambia (the)",
        "Georgia",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (the)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",

        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic (the)",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands (the)",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova (the Republic of)",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands (the)",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger (the)",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands (the)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines (the)",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation (the)",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",

        "Sri Lanka",
        "Sudan (the)",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands (the)",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates (the)",
        "United States Minor Outlying Islands (the)",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ];
    }
    /** Laboratories  */
    public insertLaboratory(obj: any){
      const headers = this.headers;
      return this.http.post(this.url + 'laboratories', obj, {headers});
    }

    public getLaboratories(account: string){
      const headers = this.headers;
      const obj = {account: account};
      return this.http.post(this.url + 'open/laboratories', obj, {headers});
    }

    public deleteLaboratory(params?: any){
      return this.del('laboratories', params);
    }

    public getTypeID(account: string){
      const headers = this.headers;
      const obj = {account: account};
      return this.http.post(this.url + 'open/type_id', obj, {headers});
    }

    private get(objectName: string, params?: any, page?: number){
        const headers = this.headers;
        let query_params = '?';
        if(params){
            for(let i of Object.keys(params)){
                if(typeof params[i] !=  'object'){
                    query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
                }else{
                    const value = params[i]['value'];
                    const operator = params[i]['operator'];
                    query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
                }
            }
        }
        if(page) if(page > 0) query_params += `&page=${page}`;
        return this.http.get(this.url + `${objectName}${query_params}`, {headers}).toPromise();
    }
    private search(objectName: string, params?: any){
        const headers = this.headers;
        let query_params = '?';
        if(params){
            for(let i of Object.keys(params)){
                if(typeof params[i] !=  'object'){
                    query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
                }else{
                    const value = params[i]['value'];
                    const operator = params[i]['operator'];
                    query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
                }
            }
        }
        return this.http.get(this.url + `${objectName}${query_params}`, {headers}).toPromise();
    }
    private del(objectName: string, params?: any){
        const headers = this.headers;
        let query_params = '?';
        if(params){
            for(let i of Object.keys(params)){
                if(typeof params[i] !=  'object'){
                    query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
                }else{
                    const value = params[i]['value'];
                    const operator = params[i]['operator'];
                    query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
                }
            }
        }
        return this.http.get(this.url + `${objectName}${query_params}`, {headers}).toPromise();
    }
    public insertAccount(account: any){
        const headers = this.headers;
        return this.http.post(this.url + 'accounts', account, {headers});
    }

}
