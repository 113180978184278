<div class="overlay" *ngIf="isLoading" >
  <i class="material-icons">refresh</i>
</div>
<div class="container {{shownAccount}}" id="logo">
  <div class="logo-container">
    <a href="/{{shownAccount}}/patients">
      <img class="client-logo" src='/assets/images/{{shownAccount}}.png?1'/>
    </a>
  </div>

  <div class="" *ngIf="step !== 'registered' && step !== 'finished'" >
    <h1 *ngIf="accountInfo.study != 'LFT'" >Welcome</h1>
    <h1 *ngIf="accountInfo.study == 'LFT'" >Antigen Rapid Test<br/>Registration form</h1>
    <p>First we need to ask you, are you 18 years or over?</p>
    <div class="flexible">
      <div class="flexible-column">
        <button class="button" [ngClass]="older === 1 ? 'active' : ''" (click)="setOlder(1)">Yes I'm older</button>
      </div>
      <div class="flexible-column">
        <button class="button" [ngClass]="older === 0 ? 'active' : ''" (click)="setOlder(0)">No, I'm younger</button>
      </div>
    </div>

    <p>Please review our Privacy Policy which provides information on how we use and process your data - it's available at:<br>
        <span *ngFor="let l of accountInfo.links"><a href="{{l}}">{{l}}</a><br/></span>
    </p>
    <p *ngIf="accountInfo.representative" >You can also contact us at any time if you no longer wish to keep your data with us by e-mailing <a href="mailto:{{accountInfo.representative.email}}">{{accountInfo.representative.email}}</a></p>

    <div *ngIf="older > -1" >
      <p>Second, what are you looking for?</p>
      <div class="flexible">
          <div class="flexible-column">
            <button (click)="setRegistering()" class="button" [ngClass]="step === 'registering' ? 'active' : ''">Register for a test</button>
          </div>
          <div class="flexible-column view-results-button-container">
            <a class="button" target="_blank" href="/{{account}}/results">View results</a>
          </div>
      </div>
    </div>
    <div *ngIf="step === 'registering'" >
      <h3>To request a test please fill out the form below</h3>
      <form id="registering" class="form-horizontal ion-margin-top" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <input *ngIf="f.firstname" class="form-input" placeholder="* First name" formControlName="firstname" name="firstname"/>
        <p *ngIf="f.firstname.errors && (f.firstname.touched || submitted)" class="form-error">The first name is required</p>
        <input *ngIf="f.surname" class="form-input" placeholder="* Surname" formControlName="surname" name="surname"/>
        <p *ngIf="f.surname.errors && (f.surname.touched || submitted)" class="form-error">The surname is required</p>
        <input *ngIf="f.email" class="form-input" placeholder="* Email" type="email" formControlName="email" name="email"/>
        <div *ngIf="f.email">
          <p *ngIf="f.email.errors && (f.email.touched || submitted)" class="form-error">The email is invalid</p>
        </div>
        <input *ngIf="older === 0"  class="form-input" placeholder="Parent or guardian's name" formControlName="parent_name" name="parent_name"/>

        <input *ngIf="f.mobile_number" class="form-input" placeholder="Mobile phone" formControlName="mobile_number" name="mobile_number"/>

        <input *ngIf="older === 0" class="form-input" placeholder="Parent or guardian's email" type="email" formControlName="parent_email" name="parent_email"/>
        <p *ngIf="older === 0 && f.parent_email.errors && (f.parent_email.touched || submitted)" class="form-error">The email is invalid</p>
        <input *ngIf="older === 0 && f.parent_mobile_number" class="form-input" placeholder="Mobile phone" formControlName="parent_mobile_number" name="parent_mobile_number"/>
        <h3>Identification</h3>

        <select class="form-input" name="document_type" formControlName="document_type" (change)="documentChanged($event)">
          <option disabled="" selected="" value="">* Document type</option>
          <option *ngFor="let t of type_ids"  [value]="t.id">{{t.type_id}}</option>
        </select>
        <p *ngIf="f.document_type.errors && (f.document_type.touched || submitted)" class="form-error">The {{selectedDocument}} is required</p>
        <input class="form-input" placeholder="* {{selectedDocument}}" formControlName="document_number" name="document_number"/>
        <p *ngIf="f.document_number.errors && (f.document_number.touched  || submitted)" class="form-error">The {{selectedDocument}} is required</p>
        <select *ngIf="f.country"  name="country" formControlName="country" class="form-input">
          <option selected="selected" value=''>* Please select a country</option>
          <option *ngFor="let c of countries; let i = index"  [value]="i">{{c}}</option>
        </select>
        <div *ngIf="f.birthdate" class="input-box-container">
          <input *ngIf="f.birthdate" class="form-input" placeholder="* Select your birth date" angular-mydatepicker name="birthdate" (click)="dp.toggleCalendar()" (keydown)="dp.toggleCalendar()"
             formControlName="birthdate" [options]="dateOptions" #dp="angular-mydatepicker"/>
          <p *ngIf="f.birthdate.errors && (f.birthdate.touched || submitted)" class="form-error">The birthdate is required</p>
        </div>
        <input *ngIf="f.town" class="form-input" placeholder="Town / City" formControlName="town" name="town"/>
        <input *ngIf="f.postcode" class="form-input" placeholder="Postcode" formControlName="postcode" name="postcode"/>
        <input *ngIf="f.address1" class="form-input" placeholder="Address line 1 (or Company Name)" formControlName="address1" name="address1"/>
        <input *ngIf="f.address2" class="form-input" placeholder="Adress line 2" formControlName="address2" name="address2"/>

        <select *ngIf="f.ethnicity"  name="ethnicity" formControlName="ethnicity" class="form-input">
          <option selected="selected" value=''>* Ethnicity</option>
          <option value="White">White</option>
          <option value="Mixed">Mixed or Multiple ethnic groups</option>
          <option value="Asian">Asian or Asian British</option>
          <option value="Black">Black, African, Caribbean or Black British</option>
          <option value="Other">Other ethnic group</option>
        </select>

        <select *ngIf="f.gender"  name="gender" formControlName="gender" class="form-input">
          <option selected="selected" value=''>* Gender</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </select>

        <input *ngIf="f.staff_id" class="form-input" placeholder="Staff ID" formControlName="staff_id" name="staff_id"/>
        <input *ngIf="f.student_id" class="form-input" placeholder="Student ID" formControlName="student_id" name="student_id"/>

        <h3 *ngIf="f.country_from" >Travel information</h3>
        <input *ngIf="f.country_from" class="form-input" placeholder="Which country are you traveling back to the UK" formControlName="country_from" name="country_from"/>
        <div *ngIf="f.arrival_date" class="input-box-container">
          <input *ngIf="f.arrival_date" class="form-input" placeholder="* Date of arrival back to the UK?" angular-mydatepicker name="arrival_date" (click)="dp2.toggleCalendar()" (keydown)="dp2.toggleCalendar()"
              formControlName="arrival_date" [options]="dateOptions" #dp2="angular-mydatepicker"/>
        </div>
        <select *ngIf="f.transport_type"  name="transport_type" formControlName="transport_type" class="form-input">
          <option selected="selected" value=''>* Transport type</option>
          <option value="flight">Flight</option>
          <option value="train">Train</option>
          <option value="vessel">Vessel</option>
          <option value="other">Other</option>
        </select>
        <input *ngIf="f.transport_number" class="form-input" placeholder="Transpor number: e.g Flight nr, Coach nr, etc..." formControlName="transport_number" name="transport_number"/>
        <div *ngIf="f.vaccine_type">
          <h3>Vaccination status</h3>
          <label class="checkbox-container">
          Are you fully vaccinated ?
          </label>
          <label class="checkbox-container" (click)="vaccination_status = true">
            <input *ngIf="f.vaccine_type" [checked]="vaccination_status" type="radio"/>Yes (2 or more doses)
          </label>
          <label class="checkbox-container" (click)="vaccination_status = false">
            <input *ngIf="f.vaccine_type" [checked]="!vaccination_status" type="radio"/>No (1 or 0 dose)
          </label>
        </div>
        <input *ngIf="f.vaccine_type" class="form-input" placeholder="Vaccination type (if known)" formControlName="vaccine_type" name="vaccine_type"/>
        <input *ngIf="f.passport_number" class="form-input" placeholder="NHS domestic pass" formControlName="passport_number" name="passport_number"/>
        <input *ngIf="f.booking" class="form-input" placeholder="Test booking reference: e.g LABMEXXXXXXX" formControlName="booking" name="booking"/>

        <h3 [ngClass]="studyType == 'LFT' ? 'hidden' : ''">Laboratory</h3>
        <select name="laboratory" formControlName="laboratory" class="form-input" (change)="labChanged($event)" [ngClass]="studyType == 'LFT' ? 'hidden' : ''">
          <option selected="selected" value=''>* Please select an option</option>
          <option *ngFor="let l of laboratories"  [value]="l.id">{{l.name}}</option>
        </select>
        <p *ngIf="f.laboratory.errors && (f.laboratory.touched || submitted)" class="form-error">The laboratory is required</p>

        <div *ngIf="studyType != 'LFT'" >
          <h3>Sample IDs</h3>
          <p>Enter a comma separated list of Sample IDs</p>
          <input class="form-input" placeholder="Sample ID" formControlName="sample_id" name="sample_id"/>
          <p *ngIf="f.sample_id.errors && (f.sample_id.touched || submitted)" class="form-error">At least one sample id is required</p>
        </div>
        <div *ngIf="studyType == 'LFT'" >
          <h3>Lateral Flow Test Interpretation</h3>
          <select *ngIf="f.result"  name="result" formControlName="result" class="form-input">
            <option selected="selected" value=''>Select your test result</option>
            <option value='0'>Positive</option>
            <option value='1'>Negative</option>
            <option value='2'>Inconclusive</option>
          </select>
          <p *ngIf="f.result.errors && (f.result.touched || submitted)" class="form-error">Select your test result</p>
          <div class="input-box-container">
            <input  class="form-input" placeholder="* Enter your activation code" name="activation_code"
               formControlName="activation_code"/>
            <p *ngIf="f.activation_code.errors && (f.activation_code.touched || submitted)" class="form-error">The activation code is required</p>
          </div>
          <p>Upload your lateral flow test</p>
          <div *ngIf="imageName == ''" >
          <input type="file" (change)="fileChangeEvent($event)" class="fileInputProfile" style="display: none" #hiddenfileinput [disabled]="!f.result.value"/>
          <button (click)="hiddenfileinput.click()" [ngClass]="f.result.value == '' ? '' : 'active'" class="button">Add image</button>
          <p *ngIf="!f.result.value  && (f.sample_id.touched || submitted)" class="form-error">Please select your test result</p>
          <p *ngIf="!uploading && f.result.value && f.sample_id.errors && (f.sample_id.touched || submitted)" class="form-error">At least one test image is required</p>
          <p *ngIf="uploading" class="form-error">Uploading...</p>
          </div>
          <div *ngIf="imageName != ''" >
            <div class="text-left">
              <i class="material-icons text-red" (click)="removeImage()">close</i> {{imageName}}
            </div>
          </div>
        </div>

        <input type="submit" value="Submit" [ngClass]="!loginForm.invalid && f.sample_id.value != '' ? 'active' : ''"   class="button full-width margin-top"/>
      </form>
      <p class="disclaimer text-red" *ngIf="loginForm.invalid || f.sample_id.value == ''" >* Required information</p>
    </div>
  </div>
  <div *ngIf="step === 'registered'">
      <div style="width: 100%;" id="registered">

        <p style="margin-top: 0;">Please confirm that your information is correct:</p>
        <hr>
        <h1 *ngIf="accountInfo.study != 'LFT'" >PARTICIPANT CONSENT FORM</h1>
        <h1 *ngIf="accountInfo.study == 'LFT'" >CONSENT FORM</h1>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>18 years or over?</strong></p>
            <p class="text-right flexible-column">{{older ? 'Yes' : 'No'}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>First name</strong></p>
            <p class="text-right flexible-column">{{f.firstname.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Surname</strong></p>
            <p class="text-right flexible-column">{{f.surname.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Email</strong></p>
            <p class="text-right flexible-column">{{f.email.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Mobile phone</strong></p>
            <p class="text-right flexible-column">{{f.mobile_number.value}}</p>
        </div>
        <div class="flexible between" *ngIf="!older">
            <p class="text-left flexible-column"><strong>Parent / guardian's name</strong></p>
            <p class="text-right flexible-column">{{f.parent_name.value}}</p>
        </div>
        <div class="flexible between" *ngIf="!older">
            <p class="text-left flexible-column"><strong>Parent / guardian's email</strong></p>
            <p class="text-right flexible-column">{{f.parent_email.value}}</p>
        </div>
        <div class="flexible between" *ngIf="!older && f.parent_mobile_number" >
            <p class="text-left flexible-column"><strong>Parent / guardian's mobile phone</strong></p>
            <p class="text-right flexible-column">{{f.parent_email.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Document number</strong></p>
            <p class="text-right flexible-column">{{f.document_number.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Type of document</strong></p>
            <p class="text-right flexible-column">{{selectedDocument}}</p>
        </div>
        <div class="flexible between" *ngIf="f.country">
            <p class="text-left flexible-column"><strong>Country / Region</strong></p>
            <p class="text-right flexible-column">{{selectedCountry}}</p>
        </div>
        <div class="flexible between" *ngIf="f.town">
            <p class="text-left flexible-column"><strong>Town / City</strong></p>
            <p class="text-right flexible-column">{{f.town.value}}</p>
        </div>
        <div class="flexible between" *ngIf="f.postcode">
            <p class="text-left flexible-column"><strong>Postcode</strong></p>
            <p class="text-right flexible-column">{{f.postcode.value}}</p>
        </div>
        <div class="flexible between" *ngIf="f.address1">
            <p class="text-left flexible-column"><strong>Address line 1 (or Company name)</strong></p>
            <p class="text-right flexible-column">{{f.address1.value}}</p>
        </div>
        <div class="flexible between" *ngIf="f.address2">
            <p class="text-left flexible-column"><strong>Address line 2</strong></p>
            <p class="text-right flexible-column">{{f.address2.value}}</p>
        </div>
        <div class="flexible between" *ngIf="f.staff_id">
            <p class="text-left flexible-column"><strong>Staff ID</strong></p>
            <p class="text-right flexible-column">{{f.staff_id.value}}</p>
        </div>
        <div class="flexible between" *ngIf="f.student_id">
            <p class="text-left flexible-column"><strong>Student ID</strong></p>
            <p class="text-right flexible-column">{{f.student_id.value}}</p>
        </div>
        <div class="flexible between">
            <p class="text-left flexible-column"><strong>Laboratory</strong></p>
            <p class="text-right flexible-column">{{selectedLab}}</p>
        </div>
        <div *ngIf="accountInfo.study" >
          <div *ngIf="accountInfo.study != 'LFT'" >
            <p>Thank you for considering taking part in this research project. Please complete this form after you have read the Information Sheet and/or listened to an explanation about the research project.</p>
            <p>Title of Study: <span>{{accountInfo.study}}</span></p>
            <p>Diagnostic platform: {{accountInfo.platform}}</p>
            <p>Chief Investigator: <span>{{accountInfo.representative.name}} {{accountInfo.representative.surname}}</span>. <a href="mailto:{{accountInfo.representative.email}}">{{accountInfo.representative.email}}</a></p>
            <p>By initialling each box, you are consenting to this element of the study. It will be assumed that un-initialled boxes mean that you DO NOT consent to that part of the study and you may be deemed ineligible for the study.</p>
          <p>After signing this Consent Form, please keep a copy for your records. You may also keep the Information Sheet and refer to these at any time.</p>
          </div>
          <div *ngIf="accountInfo.study == 'LFT'" >
            <p>By initialling each box, you are consenting to this element of the test. It will be assumed that un-initialled boxes mean that you DO NOT consent and you may be deemed ineligible for the validation of your test.</p>
            <p>After signing this Consent Form, please keep a copy for your records.</p>
          </div>
        </div>
        <hr>

        <h3 *ngIf="accountInfo.study != 'LFT'">Taking part in a study, please tick off every box</h3>
        <h3 *ngIf="accountInfo.study == 'LFT'">Submitting my results</h3>
        <p class="text-left flexible-column"><strong>STATEMENT</strong></p>

        <div class="clauses-container">
          <label *ngFor="let c of accountInfo.clauses"><input [(ngModel)]="c.checked" [value]="c" [checked]="c.required" type="checkbox"/>{{c.text}}{{c.required ? ' *' : ''}}</label>
        </div>

        <hr>

        <h3 *ngIf="accountInfo.study != 'LFT'" >Future use of the information in the study, please tick off every box</h3>
        <h3 *ngIf="accountInfo.study == 'LFT'" >Future use of the information, please tick off every box</h3>

        <p class="text-left flexible-column"><strong>STATEMENT</strong></p>

        <div class="clauses-container">
          <label *ngFor="let c of accountInfo.futureClauses"><input [(ngModel)]="c.checked" [value]="c" [checked]="c.required" type="checkbox"/>{{c.text}}{{c.required ? ' *' : ''}}</label>
        </div>
        <re-captcha class="margin-top" (resolved)="resolved($event)" (error)="errored($event)" errorMode="handled"></re-captcha>
        <div>
          <div>
              <input type="button" [disabled]="canSubmit ? false : true" class="button full-width margin-top {{canSubmit ? 'active' : ''}}" (click)="continue()" value="CONTINUE" >
          </div>
          <div>
              <input type="button" class="button full-width margin-top" (click)="edit()" value="GO BACK TO EDIT">
          </div>
        </div>
        <p class="stay-safe">Stay safe!</p>
        <img *ngIf="accountInfo.study != 'LFT'"  class="vidiia-logo" src="/assets/images/vidiia.svg"/>
        <p *ngIf="accountInfo.study != 'LFT'"><a href="https://vidiia.co.uk" target="_blank">https://vidiia.co.uk</a></p>
    </div>
  </div>
  <div *ngIf="step === 'finished'" >
    <div>
      <h3>Test confirmation</h3>
      <h3>{{selectedDocument}}</h3>
      <h2>{{f.document_number.value}}</h2>

      <div *ngIf="accountInfo.study != 'LFT'" >
        <p>Please keep this information safe, after attending to your test  please return to this form and click <a target="_blank" href="/{{account}}/results">VIEW RESULTS</a>.</p>
        <p class="text-red"><strong>You will require your Patient ID and Sample ID (this will be provided on the day of the test) in order to retrieve your results.</strong></p>
      </div>
      <div *ngIf="accountInfo.study == 'LFT'" >
        <p>Please keep this information safe. Your validated tests results will be issued by email.</p>
      </div>
      <p>Please notice that we will never share your details with any third party, all the information provided us confidential and we will only use your contact details to inform the status of your test.</p>

      <div class="text-center">
          <a type="button" class="button full-width active" value="OUR PRIVACY TERMS" href="{{accountInfo.privacyTerms}}">Our Privacy Terms</a>
      </div>
      <div class="text-center">
          <input type="button" class="button full-width active margin-top" (click)="pdf()" value="DOWNLOAD CONSENT FORM">
      </div>
      <div class="text-center" *ngIf="accountInfo.study == 'LFT'" >
        <p>
          <a type="button" class="button full-width active" value="OUR PRIVACY TERMS" href="https://forms.vidiia.com/{{shownAccount}}/patients">Upload another test</a>
        </p>
      </div>


      <p class="stay-safe">Stay safe!</p>
      <img *ngIf="accountInfo.study != 'LFT'"  class="vidiia-logo" src="/assets/images/vidiia.svg"/>
      <p *ngIf="accountInfo.study != 'LFT'"><a href="https://vidiia.co.uk" target="_blank">https://vidiia.co.uk</a></p>
    </div>
  </div>
