import { ResultsComponent } from './pages/results/results.component';
import { ApiService } from './services/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { PatientsComponent } from './pages/patients/patients.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { OnlineStatusModule } from 'ngx-online-status';
import { Ng2ImgMaxModule } from 'ng2-img-max';

@NgModule({
  declarations: [
    AppComponent,
    PatientsComponent,
    ResultsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    AngularMyDatePickerModule,
    OnlineStatusModule,
    Ng2ImgMaxModule
  ],
  providers: [
    ApiService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
          siteKey: '6Ldagn0aAAAAABeXGXABBq0hJVnr9j6K-E4dLfW2',
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
