export class PatientModel {
  id: number = 0;
  firstname: string = '';
  surname: string = '';
  address1: string = '';
  address2: string = '';
  country: string = '';
  postcode: string = '';
  town: string = '';
  email: string = '';
  mobile_number: string = '';
  parent_name: string = '';
  parent_email: string = '';
  parent_mobile_number: string = '';
  staff_id: string = '';
  student_id: string = '';

  birthdate: string = '';

  ethnicity: string = '';
  gender: string = '';

  status: number = 0;
  country_from: string = '';
  arrival_date: string = '';
  transport_type: string = '';
  transport_number: string = '';
  vaccine_type: string = '';
  passport_number: string = '';
  booking: string = '';
  vaccination_status = false;
}
