import { ResultsComponent } from './pages/results/results.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientsComponent } from './pages/patients/patients.component';

const routes: Routes = [
  {
    path: ':account/patients',
    component: PatientsComponent
  },
  {
    path: ':account/results',
    component: ResultsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
